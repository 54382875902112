export function FormGroups(props: { children: React.ReactNode }) {
  return <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">{props.children}</div>;
}

export interface FormGroupProps {
  children: React.ReactNode;
  name?: string;
  description?: string;
  enabler?: boolean;
  extra?: React.ReactNode;
  plain?: boolean;
  onRemove?: () => void;
  enablerDisabled?: boolean;
}

export interface FormHorizontalViewProps {
  children: React.ReactNode;
  name: React.ReactNode;
  errors: string[];
  caption?: string;
  first?: boolean;
  simple?: boolean;
  description?: string;
  border?: boolean;
  required?: boolean;
}

export function FormHelperView(props: { description?: string; error?: string; children: React.ReactNode }) {
  return (
    <>
      {props.children}
      {props.error && <p className="mt-2 text-xs text-red-700">{props.error}</p>}
      {props.description && <p className="mt-2 text-xs text-gray-500">{props.description}</p>}
    </>
  );
}

export interface FormHelperViewProps {
  children: React.ReactNode;
  description?: string;
  errors?: string[];
}

export interface FormHorizontalProps {
  label: string;
  error?: string;
  name?: string; // TODO: remove
  required?: boolean;
  description?: string;
  children: React.ReactNode;
  simple?: boolean;
  first?: boolean;
  border?: boolean;
  caption?: string;
}

export function FormVertical(props: FormHorizontalProps) {
  const label = (
    <label className="block font-medium text-dark sm:mt-px flex flex-row items-center justify-between ">
      {props.label}
      {props.required && (
        <span className="flex justify-center items-center bg-black text-white leading-none rounded-md p-1 ml-2 text-xs select-none">
          Required information
        </span>
      )}
    </label>
  );

  const helper = (
    <FormHelperView error={props.error || undefined} description={props.description}>
      {props.children}
    </FormHelperView>
  );

  if (props.simple) {
    return <div className={`first:pt-0`}>{helper}</div>;
  }

  return (
    <div className="first:pt-0">
      <div>{label}</div>
      {props.caption && <div className="text-xs text-gray-600">{props.caption}</div>}
      <div className="mt-2 sm:col-span-3">{helper}</div>
    </div>
  );
}

export function FormHorizontal(props: FormHorizontalProps) {
  let borderClass = 'sm:pt-1';

  if (props.border) {
    borderClass = `pt-5 border-t sm:border-gray-200 ${props.first ? 'first:border-0' : ''}`;
  }

  const label = (
    <label className="block text-sm font-medium text-gray-600 sm:mt-2">
      {props.label}
      {props.required ? '*' : null}
    </label>
  );

  const helper = (
    <FormHelperView error={props.error || undefined} description={props.description}>
      {props.children}
    </FormHelperView>
  );

  if (props.simple) {
    return <div className={`first:pt-0 ${borderClass}`}>{helper}</div>;
  }

  return (
    <div className={`sm:grid sm:grid-cols-4 sm:gap-4 ${props.first ? 'first:pt-0' : ''} ${borderClass}`}>
      <div>
        {label}
        {props.caption && <div className="text-xs text-gray-600">{props.caption}</div>}
      </div>
      <div className="mt-1 sm:mt-0 sm:col-span-3">{helper}</div>
    </div>
  );
}
